import React from 'react'
import Layout, { LAYOUT_TYPES } from '../modules/Layout'
import { graphql, Link } from 'gatsby'
import Main from '../modules/Main'
import { LANGUAGE, TEMPLATE_TYPES } from '../common/constants'
import useSeo from '../hooks/useSeo'
import { Flex, Box } from 'rebass/styled-components'
import { Text, theme } from '@roar/components'
import { formatImage } from '../modules/ImageWrapper'

const DATA_MAP = {
  title: ({ name, date }) => `${name} - ${date}`,
  description: ['description', 'childMarkdownRemark', 'excerpt'],
  image: (value) => formatImage(value),
  altText: ['description', 'childMarkdownRemark', 'excerpt'],
  publishDate: ['date'],
}

const getLayout = (data, fallbackRelatedPosts, helmetProps, pageContext, allContentfulPosts) => {
  const { name, slug, date, image, content, postType, relatedBlogPosts, category, imageDisplayOptions, video } = data

  let imageLayout = {}

  if (video) {
    imageLayout = {
      __typename: LAYOUT_TYPES.VIDEO,
      ...video,
    }
  } else {
    if (!imageDisplayOptions || imageDisplayOptions === 'Full width') {
      imageLayout = {
        __typename: LAYOUT_TYPES.IMAGE,
        image: image,
        loading: 'eager',
      }
    }
  }

  const fallbackPosts = fallbackRelatedPosts.edges
    .map((e) => {
      const { node } = e
      return {
        ...node,
      }
    })
    // filtering out the post that matches the slug variable, meaning we exclude the current post from the array.
    .filter((b) => b.slug !== slug)
    .slice(0, 6)

  const allPostsEdges = allContentfulPosts.edges
    .map((e) => {
      const { node } = e
      return {
        ...node,
      }
    })
    // filtering out the post that matches the slug variable, meaning we exclude the current post from the array.
    .filter((b) => b.slug !== slug)
    .slice(0, 6)

  // Check if the post type is 'Chapter'
  if (postType && postType.name === 'Chapter') {
    return [
      {
        __typename: LAYOUT_TYPES.MASTHEAD,
        headline: name,
        eyebrow: (() => {
          return postType ? (
            <Flex alignItems="center" justifyContent={'center'}>
              <Link to={`/${pageContext?.parentPage?.slug}`}>
                <Text
                  color={theme.colors.textDark}
                  size="small"
                  letterSpacing={theme.letterSpacing.small}
                  style={{ textTransform: 'uppercase' }}
                >
                  {pageContext?.parentPage?.name}
                </Text>
              </Link>
              <Box mx={[1]}>
                <i className={`icon icon-arrow-right`} />
              </Box>
              <Text
                color={theme.colors.brandRed}
                size="small"
                letterSpacing={theme.letterSpacing.small}
                style={{ textTransform: 'uppercase' }}
              >
                Chapter {pageContext?.parentPage?.index}
              </Text>
            </Flex>
          ) : null
        })(),
      },
      { ...imageLayout },
      {
        __typename: LAYOUT_TYPES.BLOG_CONTENT,
        content: content,
        image: imageDisplayOptions && imageDisplayOptions === 'In content' ? image : null,
        shareProps: { ...helmetProps },
      },
      {
        __typename: LAYOUT_TYPES.HELMET,
        ...helmetProps,
        category: pageContext?.category,
        breadcrumb: pageContext?.postType,
      },
    ]
  }

  return [
    {
      __typename: LAYOUT_TYPES.MASTHEAD,
      headline: name,
      date: date,
      eyebrow: (() => {
        const categoryComp =
          category && category.length ? (
            <Text
              color={theme.colors.brandRed}
              size="small"
              letterSpacing={theme.letterSpacing.small}
              style={{ textTransform: 'uppercase' }}
            >
              {category[0].name}
            </Text>
          ) : null
        return postType ? (
          <Flex alignItems="center" justifyContent={'center'}>
            <Link to={`/${postType.name.toLowerCase()}`}>
              <Text
                color={theme.colors.textDark}
                size="small"
                letterSpacing={theme.letterSpacing.small}
                style={{ textTransform: 'uppercase' }}
              >
                {postType.name}
              </Text>
            </Link>
            {(postType && ['Blog', 'Video'].includes(postType.name) && category && category.length) || categoryComp ? (
              <Box mx={[1]}>
                <i className={`icon icon-arrow-right`} />
              </Box>
            ) : null}
            {postType && ['Blog', 'Video'].includes(postType.name) && category && category.length ? (
              <Link to={`/${postType.name.toLowerCase()}/${category[0].slug}`}>{categoryComp}</Link>
            ) : (
              categoryComp
            )}
          </Flex>
        ) : null
      })(),
    },
    { ...imageLayout },
    {
      __typename: LAYOUT_TYPES.BLOG_CONTENT,
      content: content,
      image: imageDisplayOptions && imageDisplayOptions === 'In content' ? image : null,
      shareProps: { ...helmetProps },
    },
    {
      __typename: LAYOUT_TYPES.BLOGS,
      related: relatedBlogPosts,
      blogs:
        relatedBlogPosts && relatedBlogPosts.length
          ? relatedBlogPosts
          : fallbackPosts && fallbackPosts.length
          ? fallbackPosts
          : allPostsEdges,
      title: LANGUAGE.blogs_related_posts_description,
      showPagination: false,
      isSearchable: false,
      isRelatedPosts: true,
    },
    {
      __typename: LAYOUT_TYPES.HELMET,
      ...helmetProps,
      category: pageContext?.category,
      breadcrumb: pageContext?.postType,
    },
  ]
}

const BlogPost = (props) => {
  const {
    data: { contentfulPosts, fallbackRelatedPosts, allContentfulPosts },
  } = props

  const url = props.location.href

  const seoProps = useSeo(contentfulPosts, DATA_MAP, TEMPLATE_TYPES.POST)
  const layout = getLayout(contentfulPosts, fallbackRelatedPosts, seoProps, props.pageContext, allContentfulPosts)

  return (
    <Main {...props} path={url}>
      <Layout layout={layout} path={url} />
    </Main>
  )
}

export default BlogPost

export const blogPostQuery = graphql`
  query PostBySlug($slug: String!, $category: String, $postType: String) {
    contentfulPosts(slug: { eq: $slug }) {
      ...Post
      structuredData {
        altText
        noIndex
        noFollow
        description {
          description
        }
        image {
          title
          gatsbyImageData(layout: FULL_WIDTH)
        }
        headline
      }
    }
    allContentfulPosts(
      limit: 7
      sort: { order: DESC, fields: date }
      filter: { postType: { name: { eq: $postType } } }
    ) {
      edges {
        node {
          ...PostArchive
        }
      }
    }
    fallbackRelatedPosts: allContentfulPosts(
      filter: { category: { elemMatch: { slug: { eq: $category } } } }
      limit: 7
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          ...PostArchive
        }
      }
    }
  }
`
